import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Дом | Гараж - автомобильное обслуживание и многое другое
			</title>
			<meta name={"description"} content={"Добро пожаловать в гараж, ваше место для первоклассного автомобильного обслуживания и технического обслуживания."} />
			<meta property={"og:title"} content={"Дом | Гараж - автомобильное обслуживание и многое другое"} />
			<meta property={"og:description"} content={"Добро пожаловать в гараж, ваше место для первоклассного автомобильного обслуживания и технического обслуживания."} />
			<meta property={"og:image"} content={"https://qmist-juns.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://qmist-juns.com/img/4249144.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qmist-juns.com/img/4249144.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qmist-juns.com/img/4249144.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qmist-juns.com/img/4249144.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qmist-juns.com/img/4249144.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qmist-juns.com/img/4249144.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://qmist-juns.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Гараж{" "}
					<br />
					{" "}Автомобильное обслуживание и многое другое
				</Text>
				<Text
					margin="0px 150px 32px 150px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Добро пожаловать в гараж, ваше место для первоклассного автомобильного обслуживания и технического обслуживания.Наш гараж - это святилище для энтузиастов автомобилей и владельцев, которые ищут исключительную помощь и опыт для своих транспортных средств.В гараже мы понимаем связь между человеком и его машиной.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Почему выбирают гараж?
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					Наша команда квалифицированных механиков специализируется на широком спектре моделей автомобилей, предлагая экспертные услуги, независимо от того, владеете ли вы классической красотой или новейшим спортивным автомобилем.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Комплексный ассортимент обслуживания
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						От обычного технического обслуживания до сложного ремонта, наши услуги покрывают все, что вам нужно, чтобы оставаться в отличном состоянии.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Усовершенствованные диагностические инструменты
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						{" "}Мы используем новейшие диагностические инструменты для точной идентификации и исправления проблем, обеспечивая эффективные и эффективные услуги.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Персонализированный опыт работы с клиентами
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Мы верим в персонализированный подход, тратя время, чтобы понять конкретные потребности вашего автомобиля и ваши ожидания.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-8">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
				width="100%"
				min-width="100%"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 60px 0px 60px"
				lg-margin="0px 0px 50px 0px"
				sm-padding="0px 25px 0px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Уникальные преимущества
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Обновления производительности: Хотите повысить производительность вашего автомобиля?Мы предлагаем ряд услуг обновления, включая настройку двигателей, обновления выхлопных газов и многое другое.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 20px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="#5d20a9"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="300 18px/27px --fontFamily-sans" color="#505257">
						Прозрачные цены: здесь нет сюрпризов.Мы предоставляем четкие, предварительные цены на все услуги, без скрытых сборов.
					</Text>
				</Box>
				<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
					Гараж - где каждая машина остается лучше, чем она прибыла
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				padding="0px 0px 0px 60px"
				margin="0px -10px 0px 0px"
				lg-margin="0px 10px 0px 10px"
				lg-padding="0px 0px 0px 0"
			>
				<Image src="https://qmist-juns.com/img/2.jpg" display="block" border-radius="25px" />
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});